import React from 'react';

import NextImage from 'next/image';
import { usePathname } from 'next/navigation';

import { InternalLink } from '../../Clickables/InternalLink/InternalLink';
import { Heading } from '../../Heading/Heading';

import activeLinkIcon from './assets/activeLinkIcon.svg';
import { ISiteSubNavLinkItem } from './helpers/siteSubNavLinks';

interface IProps {
  navItems: ISiteSubNavLinkItem[];
}

export const SiteSubNavigation = ({ navItems }: IProps) => {
  const pathname = usePathname();

  return (
    <nav className="sticky top-[var(--main-navigation-height-mobile)] z-sub-nav min-h-[var(--main-navigation-height-mobile)] bg-white px-6 shadow sm:top-[var(--main-navigation-height)] md:px-14 lg:px-16 xl:mx-auto">
      <div className="flex min-h-[var(--main-navigation-height-mobile)] flex-row space-x-6 overflow-auto whitespace-nowrap md:space-x-7">
        {navItems.map(({ id, title, path, target, subPages }: ISiteSubNavLinkItem) => {
          const isActiveSubPage = subPages?.filter(page => pathname?.includes(page));
          const isActiveTab = path === pathname || isActiveSubPage?.length;

          return (
            <InternalLink
              key={`site-sub-nav-${id}`}
              to={path}
              variant="textNone"
              className="relative flex items-center justify-center"
              target={target || ''}
              data-testid={`site-sub-nav-${id}`}
            >
              <Heading
                withDefaults={false}
                as="span"
                variant="3xs"
                color={isActiveTab ? 'navHighlight' : 'dark'}
                className="flex flex-col items-center font-heading hover:text-pinktail-shade"
              >
                {title}
                <NextImage
                  alt="Active Link Icon"
                  width={21}
                  height={6}
                  className={`absolute bottom-0 mt-2 ${isActiveTab ? 'block' : 'hidden'}`}
                  src={activeLinkIcon}
                />
              </Heading>
            </InternalLink>
          );
        })}
      </div>
    </nav>
  );
};
